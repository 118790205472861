import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Purbani is an exquisite restaurant located in Wandstead, a vibrant
        neighborhood in the east of London. As you step inside the restaurant,
        you'll be greeted with an ambiance that's both modern and warm,
        featuring contemporary decor with a touch of traditional elements.
      </Typography>

      <Typography variant="body1" paragraph>
        The menu at Purbani offers a range of authentic Indian and Bangladeshi
        cuisine, expertly prepared by skilled chefs using the freshest and
        finest ingredients. From mouth-watering appetizers to flavourful
        entrées, each dish is thoughtfully crafted to deliver a unique and
        satisfying dining experience. Vegetarian and non-vegetarian options are
        available, catering to a diverse range of preferences and tastes.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
